/**
 * @file standalone/sd_product.scss
 *
 * \brief Library file - shared styles for stardust product pages
 *
 */

// 2019 Redesign
// Base library for shared styles for the new sd product pages.
// Lib is attached per template as needed.

@import '../theme-bootstrap';

// Breadcrumbs
.breadcrumbs {
  background: none;
  margin: 0;
  display: flex;
  .breadcrumbs__level {
    font-size: 12px;
    display: inline-flex;
    align-items: center;
    h1 {
      display: inline-flex;
    }
    a {
      @include body-text2;
      display: inline-flex;
      color: $black;
      padding: 0 10px;
      &:hover {
        text-decoration: none;
        box-shadow: none;
      }
    }
    &:before {
      content: '/';
    }
    &:first-child:before {
      content: '';
    }
  }
  .breadcrumbs__level--1 a {
    padding-#{$ldirection}: 0;
  }
  .site-content & {
    background: none;
    padding: 24px $container-pad-small 0;
    @include breakpoint($landscape-up) {
      padding: 48px $container-pad-landscape 0;
    }
  }
  &.blog-post-breadcrumbs {
    padding-bottom: 40px;
  }
}

.sd-product-grid,
.sd-product-spp__product-cross-sell,
.sd-product-spp .sd-product-cross-sell,
.sd-search-results,
.elc-search-results-message-wrapper,
.elc-search-grid-wrapper,
.elc-search-results-wrapper {
  .elc-product-notify-me-button {
    height: 48px;
    font-family: $font--lars;
    text-transform: unset;
    font-size: 12px;
  }
  .elc-product-carousel-container {
    .elc-slider-view-wrapper {
      padding: 0 20px;
      .elc-slider-arrow-wrapper.slick-prev {
        // Keep product carousel arrows as default, No need for RTL support.
        left: -18px;
        $color: $color-black;
        @if $text-direction == rtl {
          left: -16px;
        }
      }
      .elc-slider-arrow-wrapper.slick-next {
        // Keep product carousel arrows as default, No need for RTL support.
        right: -18px;
        $color: $color-black;
        @if $text-direction == rtl {
          right: -16px;
        }
      }
    }
    .elc-grid-item-product {
      margin-bottom: 0;
    }
    .elc-product-quick-view-button-wrapper {
      min-height: auto;
    }
  }
  .elc-grid-item-product,
  .elc-product-brief-wrapper {
    height: auto;
    .elc-product-cta-wrapper {
      position: relative;
      margin-top: 30px;
      height: 42px;
      width: 100%;
      @include breakpoint($medium-up) {
        height: unset;
        margin: 0;
      }
    }
    .elc-cta-link {
      display: none;
    }
    &:hover,
    &:focus-within {
      .elc-product-brief-favorites-icon-wrapper {
        display: block;
      }
    }
  }
  .elc-select-a-size {
    display: none;
  }
  .elc-product-installments-price-label {
    text-align: center;
    margin-bottom: 20px;
  }
  .elc-size-picker-container {
    ~ .elc-product-installments-price-label {
      margin-top: -15px;
    }
  }
  .elc-product-brief {
    .elc-favorites-icon-wrapper {
      @include breakpoint($medium-up) {
        display: none;
      }
    }
    &:hover,
    &:focus-within {
      .elc-favorites-icon-wrapper {
        display: block;
      }
    }
  }
  .elc-product-grid-header-wrapper {
    margin: -30px auto 0;
    width: 570px;
  }
  .elc-product-grid-header {
    font-size: 24px;
    letter-spacing: 0;
    line-height: 40px;
    color: $black;
    font-family: $font--lars;
    margin-bottom: 32px;
    width: 100%;
  }
  .elc-product-grid-subheader {
    height: 53px;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 20px;
    color: $black;
    font-family: $font--lars;
  }
  .elc-product-brief-detail-wrapper {
    flex-direction: row;
    flex-wrap: wrap;
    height: 199px;
    position: relative;
  }
  .elc-grid-item-product {
    margin-bottom: 64px;
    position: relative;
  }
  .elc-grid-item-tout {
    margin-bottom: 64px;
  }
  .elc-grid-container {
    min-height: unset;
    margin: 0px 5px;
    height: auto;
  }
  .elc-product-cross-sell-header {
    justify-content: space-between;
    font-family: $font--malone;
    letter-spacing: unset;
    font-size: 24px;
    font-weight: normal;
    color: $black;
    margin: 15px;
  }
  .elc-product-brief-wrapper {
    &:hover {
      box-shadow: none;
    }
    background: none;
    box-shadow: none;
    display: flex;
    .elc-styled-product-image-container {
      order: 1;
      flex: 1 0 auto;
    }
  }
  .elc-styled-product-image-container {
    text-decoration: none;
    cursor: inherit;
  }
  .elc-product-badge-wrapper {
    #{$ldirection}: 0;
    order: 2;
    position: relative;
    display: flex;
    justify-content: center;
  }
  .elc-product-item-wrapper {
    order: 3;
    &.elc-product-product-name-wrapper {
      width: 100%;
      height: 45px;
      margin: 0 auto;
      .elc-product-name-wrapper {
        margin: 0 auto;
      }
    }
    &.elc-product-detail-wrapper {
      flex: none;
      min-height: 199px;
    }
    .elc-grid-column {
      div[aria-expanded='false'] {
        .elc-dropdown-readonly-input {
          border-bottom: none;
          font-family: $font--lars;
        }
      }
    }
    &.elc-product-price-wrapper {
      width: 100%;
    }
  }
  .elc-product-images-wrapper {
    padding-bottom: 35px;
    .field-elc-mpp-content & {
      min-height: auto;
      @include breakpoint($medium-up) {
        min-height: 21.5vw;
      }
      @include breakpoint($xxlarge-up) {
        min-height: 350px;
      }
    }
  }
  .elc-product-size-wrapper {
    margin: 0 auto;
  }
  .elc-product-description-wrapper,
  .elc-selected-size-label,
  .elc-selected-size {
    display: none;
  }
  .elc-product-price-row-wrapper {
    margin: 0 auto;
  }
  .elc-size-picker-label p,
  .elc-product-original-price {
    font-size: 14px;
    letter-spacing: 0;
    font-family: $font--lars;
    text-align: center;
    font-weight: normal;
    margin: 0 auto;
    color: $black;
    @include breakpoint($medium-up) {
      padding: 3px 0;
    }
  }
  .elc-dropdown-readonly-input,
  .elc-price-and-size,
  .elc-size-picker-wrapper {
    padding: 0;
    justify-content: center;
    font-size: 14px;
    letter-spacing: 0;
    font-family: $font--lars;
    text-align: center;
    font-weight: normal;
    margin: 0 auto;
    color: $black;
  }
  .elc-size-picker-label,
  .elc-dropdown {
    justify-content: end;
    .elc-formatted-price {
      margin-#{$rdirection}: 5px;
    }
    .elc-size {
      @if $text-direction == rtl {
        margin-left: 5px;
      }
    }
  }
  .elc-size-picker-label p,
  .elc-size-picker-container {
    padding: 10px 0;
    word-spacing: 5px;
    & ~ .elc-product-prices-wrapper,
    & ~ .elc-product-prices-clickable-wrapper {
      display: none;
    }
    @if $text-direction == rtl {
      direction: rtl;
    }
  }
  .elc-product-prices-wrapper,
  .elc-product-prices-clickable-wrapper {
    text-align: center;
    margin-bottom: 12px;
  }
  .elc-product-cta-wrapper {
    display: block;
    width: 100%;
    text-align: center;
    a {
      &.elc-product-cta,
      &.elc-custom-cta {
        padding: 0 32px;
        display: inline-block;
        width: auto;
        h4 {
          font-size: 14px;
          font-family: $font--lars;
          line-height: 48px;
        }
      }
      &.elc-product-cta {
        background-color: $black;
        color: $color-white;
        h4 {
          color: $white;
        }
      }
    }
  }
  .elc-search-sort-mobile-wrapper,
  .elc-search-sort-wrapper {
    select {
      background: none;
    }
  }
  .elc-sort-wrapper {
    select {
      background-color: transparent;
      background-size: 24px;
    }
    div[aria-expanded='true'] {
      .elc-dropdown-readonly-input {
        justify-content: flex-start;
        border-bottom: 0.5px solid $color-cream-stroke;
        padding-bottom: 5px;
      }
      .elc-dropdown-arrow {
        padding-bottom: 5px;
      }
    }
  }
  .elc-search-sort-mobile-wrapper,
  .elc-sort-wrapper,
  .elc-search-sort-wrapper {
    select {
      @include body-text;
      border: none;
      height: 40px;
      padding: 0;
      width: 130px;
      text-align: $rdirection;
      @include breakpoint($medium-up) {
        width: 120px;
        text-align: $ldirection;
      }
    }
    @include breakpoint($medium-up) {
      margin-#{$ldirection}: auto;
      margin-#{$rdirection}: 0;
      width: 12%;
    }
    div[aria-expanded='true'] {
      .elc-dropdown-readonly-input,
      .elc-dropdown-label {
        border-bottom: 0.5px solid $color-cream-stroke;
        padding-bottom: 5px;
      }
      .elc-dropdown-arrow {
        padding-bottom: 5px;
      }
    }
    .elc-dropdown-arrow {
      padding: 0;
      border: none;
      &[aria-label='close menu'] {
        .elc-dropdown-arrow-icon {
          transform: rotate(-90deg);
        }
      }
    }
    .elc-dropdown-arrow-icon {
      background: url(../img/icons/src/chevron.svg) no-repeat center;
      transform: rotate(90deg);
      width: 20px;
      height: 20px;
    }
    .elc-dropdown-readonly-input,
    .elc-dropdown-label {
      @include body-text;
      text-align: center;
      font-weight: normal;
      margin: 0;
      color: $black;
      justify-content: flex-end;
      padding: 0;
    }
    .elc-dropdown-option {
      @include body-text;
      background: none;
      @include breakpoint($medium-up) {
        font-size: 12px;
      }
      @include breakpoint($large-up) {
        font-size: 14px;
      }
    }
  }
  .elc-search-sort-mobile-wrapper {
    @include body-text;
    position: relative;
    .elc-search-sort-by-label-mobile {
      margin-#{$rdirection}: 5px;
      height: auto;
      width: auto;
    }
    select {
      height: auto;
      width: auto;
      option:first-child {
        display: none;
      }
    }
  }
  .elc-search-sort-wrapper {
    width: 50%;
    @include breakpoint($medium-up) {
      width: auto;
      min-width: 150px;
    }
    .elc-dropdown-options-list {
      width: 100%;
    }
    .elc-dropdown-readonly-input {
      margin-#{$ldirection}: 5px;
    }
    .elc-dropdown-readonly-input,
    .elc-dropdown-label {
      @include breakpoint($medium-up) {
        font-size: 12px;
      }
      @include breakpoint($large-up) {
        font-size: 14px;
      }
    }
  }
  .elc-product-grid-with-sort-only {
    background: none;
    box-shadow: none;
  }
  .elc-product-sorting-dropdown {
    background: none;
  }
  .elc-dropdown-wrapper,
  .elc-dropdown-input-wrapper,
  .elc-dropdown-arrow {
    border: none;
    padding: 0;
  }
  .elc-dropdown-options-list {
    border: none;
    background-color: $color-cream;
  }
  .elc-size-picker-wrapper {
    padding: 0;
    min-height: 40px;
    min-width: 200px;
    font-size: 12px;
    letter-spacing: 0;
    font-family: $font--lars;
    text-align: center;
    font-weight: normal;
    margin: 0 auto;
    padding-bottom: 10px;
    color: $black;
    .elc-arrow-icon {
      width: 16px;
      height: 16px;
    }
    .elc-size-picker-dropdown {
      margin-bottom: 0;
    }
    .elc-dropdown-wrapper,
    .elc-dropdown-input-wrapper {
      padding: 0;
      margin: 0 auto;
      min-width: 200px;
    }
    .elc-dropdown-readonly-input {
      justify-content: $ldirection;
      padding: 0;
      word-spacing: 5px;
    }
    .elc-dropdown-arrow {
      padding: 0;
      margin-#{$ldirection}: 5px;
      transform: rotate(90deg);
      border: none;
    }
    .elc-dropdown-options-list {
      min-width: 200px;
      border-top: 1px solid $black;
      position: static;
      .elc-dropdown-option {
        font-size: 14px;
        letter-spacing: 0;
        font-family: $font--lars;
        font-weight: normal;
        word-spacing: 5px;
        margin: 0 auto;
        color: $color-primary-600;
        padding: 8px 0;
        background: transparent;
        border-bottom: 1px solid $color-cream-stroke;
        text-align: $ldirection;
        &.highlighted {
          border: 2px solid $color-red-accessibility-highlight;
          border-radius: 4px;
        }
        &:hover {
          background: transparent;
          color: $black;
        }
      }
    }
  }
  .elc-product-item-wrapper {
    &.elc-product-product-name-wrapper {
      width: 100%;
      height: auto;
      margin-bottom: 8px;
      @include breakpoint($medium-up) {
        margin-bottom: 0;
      }
    }
  }
  .elc-product-name-wrapper,
  .elc-product-display-name-wrapper {
    max-width: 192px;
    line-height: 10px;
    margin: 0 auto;
    text-decoration: none;
    @include breakpoint($medium-up) {
      width: auto;
      line-height: inherit;
      display: flex;
      text-align: center;
      flex: 0 0 auto;
    }
  }
  .elc-clickable-wrapper {
    text-decoration: none;
    &.elc-product-image-badge-wrapper {
      display: inline-block;
      padding: 0 0 10px 0;
      @include breakpoint($medium-up) {
        min-height: 328px;
      }
    }
  }
  .elc-favorites-icon-wrapper {
    position: absolute;
    #{$rdirection}: 0;
    top: 0;
    .elc-outline-favorite-icon {
      width: 18px;
      height: 17px;
      mask-repeat: no-repeat;
    }
    .elc-favorite-icon {
      width: 24px;
      height: 27px;
      mask-repeat: no-repeat;
    }
    .elc-favorite-button-icon-on {
      padding-inline-end: 2px;
    }
  }
  .elc-product-quick-view-container-wrapper {
    margin-top: 12px;
    @include breakpoint($medium-up) {
      margin-top: 20px;
    }
  }
  .elc-rating,
  .elc-read-reviews {
    padding: 0;
    margin: 0;
  }
  .elc-product-display-name,
  .elc-product-display-name-link,
  .elc-product-sub-display-name {
    font-size: 14px;
    letter-spacing: 0;
    line-height: 20px;
    color: $black;
    font-family: $font--lars;
    text-align: center;
    text-decoration: none;
    margin: 0 auto;
    background: none transparent;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    height: 40px;
    text-overflow: ellipsis;
    &:hover {
      text-decoration: none;
    }
  }
  .elc-product-display-name-link {
    margin: 5px 0;
    min-height: 40px;
    height: auto;
  }
  .elc-product-sub-display-name {
    max-width: 70%;
  }
  .elc-product-quick-view-button-wrapper {
    min-height: 20px;
  }
  .elc-main-content-section {
    display: flex;
    flex-direction: column;
    position: relative;
  }
  .elc-product-name-wrapper {
    padding-top: 5px;
  }
  .elc-product-badge {
    bottom: 0;
    font-size: 8px;
    height: auto;
    width: 192px;
    letter-spacing: 4px;
    line-height: 11px;
    color: $black;
    font-family: $font--lars;
    padding: 9px 0;
    position: absolute;
    text-align: center;
    @include breakpoint($medium-up) {
      letter-spacing: 2px;
      margin-bottom: 0;
    }
  }
  .elc-product-image-badge-link {
    text-decoration: none;
  }
  .elc-carousel-section-wrapper {
    overflow: hidden;
    .slick-prev,
    .slick-next {
      &:not(.slick--custom):before {
        display: none;
      }
    }
  }
}

//QS button, add to bag/collection buttons
.sd-product-grid,
.sd-product-spp__product-cross-sell,
.sd-product-spp .sd-product-cross-sell,
.sd-search-results,
.elc-search-results-message-wrapper,
.elc-search-results-wrapper,
.elc-product-quick-view-wrapper {
  .elc-product-notify-me-button,
  .elc-product-quick-view-button,
  .elc-add-to-bag-button,
  .elc-add-to-collection-button,
  .elc-preorder-button,
  .elc-preorder-button-disabled {
    background: transparent;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 20px;
    color: $black;
    font-family: $font--lars;
    text-align: center;
    border: 1px solid $color-cream-stroke;
    width: auto;
    border-radius: unset;
    height: 40px;
    overflow: hidden;
    text-transform: none;
    display: block;
    margin: 0 auto;
    &::first-letter {
      text-transform: uppercase;
    }
    &:hover {
      background: transparent;
    }
    &:disabled {
      background-color: transparent;
      color: $black;
      &:hover {
        background: transparent;
      }
    }
  }
  .elc-product-grid-with-filters-only,
  .elc-product-grid-with-filters-and-sort,
  .elc-product-grid-with-sort-only {
    @include breakpoint($landscape-up) {
      min-height: 80px;
    }
  }
  .elc-product-full-cta-wrapper {
    margin: 0 auto;
    width: auto;
    background: none;
    box-shadow: none;
    padding: 0;
    position: relative;
    z-index: 0;
  }
  .elc-add-to-bag-sku-inventory-status-message {
    text-align: center;
    margin: 10px auto;
    &-message {
      color: $color-error;
    }
  }
}

.elc-product-quick-view-wrapper {
  .elc-quickview-content-prices-wrapper,
  .elc-product-prices-wrapper,
  .elc-product-prices-clickable-wrapper {
    margin-top: 25px;
    margin-bottom: 8px;
  }
  .elc-product-prices-wrapper,
  .elc-product-prices-clickable-wrapper {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    align-self: flex-start;
  }
  .elc-product-price-row-wrapper {
    margin-#{$rdirection}: 16px;
    margin-#{$ldirection}: 0;
  }
}

.elc-stars-wrapper.elc-stars-disabled {
  display: none;
}

#react-aria-modal-dialog {
  .elc-responsive-modal-mask {
    display: flex;
    flex-direction: column;
    overflow: scroll;
    @include breakpoint($medium-up) {
      overflow-y: auto;
    }
  }
}

.elc-responsive-modal-mask {
  @include slide-animation('bottom');
  // Notify Me
  .elc-product-notify-me-confirmation-wrapper,
  .elc-product-notify-me-pop-up-wrapper {
    width: 100%;
    height: 100%;
    background-color: $color-cream;
    position: relative;
    bottom: 0;
    top: 0;
    #{$ldirection}: 0;
    transform: unset;
    @include breakpoint($medium-up) {
      height: auto;
      min-height: 470px;
      margin-top: auto;
    }
    .elc-product-notify-me-confirmation-close-button,
    .elc-product-notify-me-pop-up-close-button {
      background-color: transparent;
      position: absolute;
      #{$rdirection}: 0;
      top: 0;
      margin: 16px;
      padding: 0;
      z-index: 1;
      @include breakpoint($medium-up) {
        @include swap_direction(margin, 44px 44px 0 0);
      }
      .elc-product-notify-me-confirmation-close-button-icon,
      .elc-product-notify-me-pop-up-close-button-icon {
        background-color: $black;
      }
    }
    .elc-product-notify-me-confirmation-content {
      max-width: 100%;
      .elc-product-notify-me-confirmation-icon {
        display: none;
      }
      .elc-product-notify-me-confirmation-title {
        font-size: 24px;
        font-family: $font--malone;
        text-align: $ldirection;
        margin: 100px 0 32px;
      }
      .elc-product-notify-me-confirmation-content-text {
        font-family: $font--lars;
        font-size: 12px;
        text-align: $ldirection;
      }
    }
    .elc-product-notify-me-confirmation-content,
    .elc-product-notify-me-content {
      padding: 48px 22px 0;
      display: flex;
      flex-direction: column;
      height: 100%;
      overflow-y: auto;
      @include breakpoint($medium-up) {
        padding: 60px 44px;
      }
      .elc-product-notify-me-description-label,
      .elc-product-notify-me-title-label {
        font: 12px/1 $font--lars;
        color: $black;
        margin-bottom: 32px;
        @include breakpoint($medium-up) {
          max-width: 505px;
        }
      }
      .elc-product-notify-me-title-label {
        font-weight: 700;
        order: 1;
      }
      .elc-product-notify-me-description-label {
        font-weight: normal;
        order: 2;
      }
      .elc-product-notify-me-form-wrapper {
        margin: 0;
        order: 3;
        @include breakpoint($medium-up) {
          max-width: 505px;
          order: 5;
        }
        + div {
          order: 5;
          white-space: normal;
          @include breakpoint($medium-up) {
            order: 4;
            margin-bottom: 14px;
          }
        }
        .elc-floating-label-input {
          .elc-input-error .elc-input-field,
          .elc-floating-label-string {
            font-family: $font--lars;
            font-size: 12px;
          }
          .elc-input-field {
            border-radius: 0;
            border: 1px solid $color-grey-dark;
            padding: 0 16px;
            color: $black;
            font-weight: normal;
            &:focus {
              border: 1px solid $black;
            }
            @include breakpoint($medium-up) {
              padding: 0 24px;
            }
          }
          .elc-floating-label-string {
            color: $color-grey-dark;
            font-weight: normal;
          }
        }
      }
      .elc-product-notify-me-content {
        margin: 0 0 24px;
        padding: 0;
        align-items: flex-start;
        order: 4;
        line-height: 16px;
        @include breakpoint($medium-up) {
          max-width: 505px;
          order: 3;
          margin-bottom: 20px;
        }
        .elc-checkbox-check-area {
          top: 9px;
        }
      }
      .elc-checkbox-check-area {
        width: 25px;
        height: 25px;
        .elc-checkbox-icon {
          display: none;
        }
        &.elc-checkbox-not-selected {
          background: url(../img/icons/src/tick_box.svg) no-repeat center;
        }
        &.elc-checkbox-selected {
          background: url(../img/icons/src/tick_box_checked.svg) no-repeat center;
        }
      }
      .elc-product-notify-me-notice-wrapper {
        order: 5;
        position: relative;
        flex-wrap: wrap;
        @include breakpoint($medium-up) {
          order: 4;
          margin-bottom: 14px;
          min-height: 45px;
          max-width: 505px;
        }
        .elc-checkbox-label {
          display: none;
        }
        .elc-checkbox-check-area {
          top: 0;
        }
        .elc-product-notify-me-gdpr-notice-label {
          padding-top: 0;
          line-height: 24px;
        }
        .elc-product-notify-me-notice-info {
          position: absolute;
          width: 30px;
          height: 30px;
          #{$rdirection}: 0;
          background: url(../img/icons/src/information.svg) no-repeat center/cover;
          svg {
            display: none;
          }
        }
        .elc-product-notify-me-gdpr-read-confirmation {
          width: 100%;
          padding-#{$ldirection}: 40px;
          margin: 0 0 15px;
          font-size: 12px;
          font-family: $font--lars;
          color: $color-error;
          line-height: 1em;
          @include breakpoint($medium-up) {
            margin: 0;
          }
        }
        .elc-product-gdpr-popup {
          width: 100%;
          top: 100%;
          bottom: auto;
          margin-top: 24px;
          padding: 16px;
          border: none;
          border-radius: 0;
          background-color: $black;
          color: $white;
          @include breakpoint($medium-up) {
            margin-top: 12px;
          }
          @include breakpoint($landscape-up) {
            top: auto;
            #{$ldirection}: calc(100% + 25px);
            bottom: -30px;
          }
          .elc-body--2 {
            color: inherit;
            font-family: $font--lars;
            font-size: 11px;
          }
        }
      }
      .elc-input-error {
        padding: 0 21px;
        margin-bottom: 12px;
        color: $color-error;
        .elc-elc-caption {
          font-family: $font--lars;
          font-size: 12px;
        }
      }
      .elc-checkbox-label,
      .elc-product-notify-me-gdpr-notice-label {
        @include swap_direction(padding, 8px 0 0 15px);
        color: $black;
        font-family: $font--lars;
        font-size: 12px;
        @include breakpoint($medium-up) {
          max-width: 505px;
        }
      }
      .elc-product-notify-me-confirmation-continue-button,
      .elc-product-notify-me-submit-button {
        font-family: $font--lars;
        width: 100%;
        margin-#{$ldirection}: -22px;
        border-radius: 0;
        font-size: 12px;
        padding: 0;
        height: 53px;
        letter-spacing: 0.01em;
        line-height: 53px;
        text-align: center;
        border: none;
        text-decoration: none;
        cursor: pointer;
        color: $white;
        background: $black;
        position: fixed;
        bottom: 0;
        text-transform: none;
        font-weight: normal;
        @include breakpoint($medium-up) {
          order: 6;
          width: 240px;
          height: 48px;
          line-height: 48px;
          position: unset;
          margin-#{$ldirection}: 0;
          margin-top: auto;
        }
      }
      .elc-product-notify-me-submit-button-disabled {
        background-color: $color-cream-light;
        border-color: $color-cream-light;
        pointer-events: none;
        &:focus,
        &:hover,
        &:visited {
          background-color: $color-cream-light;
          border-color: $color-cream-light;
          color: $white;
        }
      }
      .elc-product-notify-me-info {
        flex-wrap: wrap;
        order: 6;
        position: relative;
        @include breakpoint($medium-up) {
          bottom: 44px;
          #{$rdirection}: 60px;
          position: absolute;
        }
        &:hover {
          @include breakpoint($medium-up) {
            padding: 25px 0 0;
          }
          .elc-product-gdpr-popup {
            @include breakpoint($medium-up) {
              opacity: 1;
              visibility: visible;
              pointer-events: all;
              transform: translateY(0);
            }
          }
        }
        &-button {
          background: url(../img/icons/src/information.svg) no-repeat center/cover;
          height: 30px;
          width: 30px;
          padding: 0;
          outline: none;
          @include breakpoint($medium-up) {
            pointer-events: none;
          }
        }
        &-icon {
          display: none;
        }
        &-your-data {
          @include swap_direction(padding, 5px 0 0 10px);
          color: $black;
          font-family: $font--lars;
          font-size: 12px;
          @include breakpoint($medium-up) {
            pointer-events: none;
          }
        }
        .elc-product-gdpr-popup {
          position: relative;
          width: 100%;
          top: 0;
          bottom: auto;
          margin: 10px 0 75px;
          padding: 24px 26px;
          border: none;
          border-radius: 0;
          background-color: $black;
          color: $white;
          opacity: 0;
          visibility: hidden;
          transform: translateY(-5px);
          transition: all 0.25s ease-in-out;
          @include breakpoint($medium-up) {
            top: auto;
            bottom: 50px;
            #{$rdirection}: 0;
            #{$ldirection}: auto;
            position: absolute;
            margin: 0;
            padding: 13px 17px;
            max-width: 275px;
            width: 50vw;
            pointer-events: none;
            transform: translateY(10px);
          }
          &.active {
            @include breakpoint($small-down) {
              opacity: 1;
              visibility: visible;
              transform: translateY(0);
            }
          }
          a {
            color: inherit;
          }
          .elc-body--2 {
            color: inherit;
            font-family: $font--lars;
            font-size: 11px;
          }
        }
      }
    }
  }
  // QuickShop
  .elc-product-quick-view-wrapper {
    @include slide-animation('top');
    transform: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    #{$ldirection}: 0;
    #{$rdirection}: 0;
    top: 0;
    bottom: auto;
    max-width: 100%;
    width: 100%;
    height: auto;
    overflow-y: auto;
    overflow-x: hidden;
    background-color: $color-cream;
    @include breakpoint($medium-up) {
      top: auto;
      padding: 20px 0;
      bottom: 0;
      align-items: center;
    }
    @media (min-height: 768px) and (orientation: portrait) {
      bottom: 0;
      height: 100%;
    }
    .elc-product-price-wrapper {
      font-size: 12px;
    }
  }
  &.isOpen {
    animation: slideFromTop 0.5s forwards;
    @include breakpoint($medium-up) {
      animation: none;
    }
  }
}

.elc-product-quick-view-wrapper {
  .elc-product-notify-me-button,
  .elc-product-learn-more-link,
  .elc-selected-size-label,
  .elc-selected-size,
  .elc-product-description-wrapper,
  .elc-product-short-description-wrapper,
  .elc-product-original-price,
  .elc-product-price-per-unit,
  .elc-product-installments-price-label,
  .elc-add-to-bag-button {
    font: 12px/1 $font--lars;
    color: $black;
    margin: 0;
  }
  .elc-product-learn-more-link {
    margin: 0;
  }
  .elc-selected-size-label {
    margin-#{$rdirection}: 5px;
  }
  .elc-size-picker-wrapper {
    .elc-selected-size-space-row {
      display: none;
    }
  }
  .elc-product-description-wrapper,
  .elc-product-short-description-wrapper {
    line-height: 20px;
  }
  .elc-selected-size,
  .elc-product-price-per-unit {
    color: $color-grey-dark;
  }
  .elc-product-size-picker-wrapper,
  .elc-size-picker-wrapper {
    .slick-track {
      justify-content: flex-start;
      @if $text-direction == rtl {
        direction: rtl;
      }
    }
    .slick-slide {
      @include breakpoint($medium-up) {
        width: initial !important;
      }
    }
  }
  .elc-product-rating-wrapper {
    text-align: $ldirection;
    font-size: 12px;
    justify-content: flex-start;
    margin-bottom: 6px;
    .elc-read-reviews {
      padding: 0;
      margin: 0;
    }
  }
  .elc-size-picker-box,
  .elc-size-picker-box-selected {
    font-size: 10px;
    line-height: 1;
    border-radius: 0;
    padding: 0 0 6px;
    color: $color-gnav-grey;
    background: none;
    border: none;
    border-bottom: 1px solid transparent;
    transition: all 0.3s ease-in-out;
  }
  .elc-size-picker-box-selected {
    border-bottom: 1px solid $black;
    color: $black;
  }
  .elc-product-notify-me-button,
  .elc-add-to-bag-button {
    text-transform: unset;
    color: $black;
    border-radius: 0;
    width: 100%;
    background-color: transparent;
    &:hover {
      background: transparent;
    }
    height: 58px;
    @include breakpoint($medium-up) {
      height: 48px;
    }
  }
  .elc-grid-container {
    .slick-slider {
      .elc-slider-arrow-wrapper {
        top: 25%;
      }
    }
  }
  .elc-product-quick-view-content {
    flex-direction: column;
    align-items: center;
    padding: 0;
    @include breakpoint($medium-up) {
      flex-direction: row;
    }
  }
  .elc-slider-view-wrapper {
    display: flex;
    justify-content: center;
    padding: 0;
    @include breakpoint($large-up) {
      padding-#{$rdirection}: 25%;
    }
  }
  .elc-image-slider-images-wrapper,
  .elc-product-images-wrapper,
  .slick-slider {
    width: 326px;
    max-width: 326px;
  }
  .elc-image-slider-images-wrapper .elc-product-images-wrapper,
  .elc-product-image-badge-wrapper .elc-product-images-wrapper {
    background: none transparent;
  }
  .slick-dots,
  .elc-slider-dots-list {
    bottom: -20px;
    display: block;
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    text-align: center;
    width: 100%;
    li {
      margin: 0 8px;
      position: relative;
      display: inline-block;
      height: 5px;
      width: 5px;
      padding: 0;
      cursor: pointer;
      @include breakpoint($landscape-up) {
        margin: 0 4px;
        width: 12px;
        height: 12px;
      }
      button {
        border: 0;
        background: 0 0;
        display: block;
        height: 5px;
        width: 5px;
        line-height: 2px;
        outline: none;
        color: transparent;
        padding: 8px;
        cursor: pointer;
        @include breakpoint($landscape-up) {
          width: 15px;
          height: 15px;
        }
        &:focus,
        &:hover {
          outline: none;
          &:before {
            opacity: 1;
          }
        }
        &:before {
          background: $black;
          border-radius: 50%;
          content: '';
          display: block;
          width: 3px;
          height: 3px;
          top: 0;
          #{$ldirection}: 0;
          position: absolute;
          line-height: 3px;
          text-align: center;
          margin: 6px;
          opacity: 1;
          @include breakpoint($landscape-up) {
            border: $color-cream solid 2px;
            width: 7px;
            height: 7px;
            line-height: 7px;
            margin: 4px 4px 0;
          }
        }
      }
      &.slick-active button:before,
      .elc-slider-dot-button-active:before {
        border-color: $black;
        width: 5px;
        height: 5px;
        margin: 5px;
        @include breakpoint($landscape-up) {
          margin: 5px 5px 0;
        }
      }
    }
  }
  .elc-product-quick-view-close-button {
    position: absolute;
    margin: 0;
    padding-#{$rdirection}: 10px;
    padding-top: 22px;
    z-index: 1;
    top: 0;
    #{$rdirection}: 0;
    background: none transparent;
    @include breakpoint($medium-up) {
      padding: 22px;
    }
    .elc-remove-icon {
      -webkit-mask-size: 30px;
      -webkit-mask-position: center;
    }
  }
  .elc-product-badge-image-wrapper {
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    width: auto;
  }
  .elc-product-badge-wrapper {
    order: 3;
    position: relative;
    top: 50px;
    #{$ldirection}: unset;
    padding: unset;
    display: inline-block;
    text-align: $ldirection;
    margin-bottom: 15px;
    @include breakpoint($medium-up) {
      order: unset;
      top: unset;
      #{$ldirection}: 100%;
    }
    @include breakpoint($landscape-up) {
      margin-bottom: 55px;
    }
  }
  .elc-product-badge {
    margin: 0;
    padding-bottom: 13px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font: 600 8px/1 $font--lars;
  }
}
.elc-product-quick-view-content {
  .elc-image-slider-images-wrapper .elc-product-badge-wrapper {
    display: none;
  }
  .elc-product-quick-view-details-wrapper,
  .elc-right-column-section {
    margin: 20px;
    align-self: unset;
    display: flex;
    @include breakpoint($medium-up) {
      margin: 0;
      width: 326px;
    }
    .elc-add-to-bag-sku-inventory-status-message {
      margin: 10px 0 0;
      &-message {
        color: $color-error;
        font: 12px $font--lars;
      }
    }
  }
  .elc-average-rating-text,
  .elc-rating-reviews,
  .elc-read-reviews {
    font: 600 normal 12px/1 $font--lars;
    margin: 0;
    color: $black;
  }
}

.elc-product-quick-view-details-wrapper,
.elc-product-quick-view-wrapper {
  max-width: 326px;
  .elc-product-display-name,
  .elc-product-display-name-link {
    font-family: $font--malone;
    letter-spacing: unset;
    font-size: 24px;
    color: $black;
    text-decoration: none;
    background: none transparent;
    font-weight: normal;
    &:hover {
      text-decoration: none;
    }
  }
  .elc-product-sub-display-name {
    @include spp-title-and-label;
    text-align: $ldirection;
  }
  .elc-product-description-wrapper,
  .elc-product-short-description-wrapper {
    text-decoration: none;
    margin: 20px 0;
    display: inline-block;
    background: none transparent;
    text-align: $ldirection;
  }
  .elc-product-engraving {
    margin: 0;
    text-align: $ldirection;
  }
  .elc-product-cta-wrapper {
    width: 100%;
    margin: 12px 0 0;
    order: 1;
  }
  .elc-add-to-collection-button {
    width: 100%;
  }
  .quickview-content-price-cta-wrapper {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 20px;
  }
  .elc-favorite-button-wrapper,
  .elc-favorite-button {
    order: 9;
    margin-top: 20px;
    & + .elc-product-cta-wrapper {
      margin-top: 0;
      order: 8;
    }
  }
  .elc-favorite-button {
    outline: none;
    width: 50%;
    border: none;
    background-color: transparent;
    justify-content: flex-end;
    order: 9;
    flex: 1 auto;
    padding: 0;
    @include breakpoint($medium-up) {
      order: 6;
    }
    p {
      order: 1;
      color: $black;
      letter-spacing: unset;
      font: 400 normal 12px/1 $font--lars;
    }
  }
  .elc-favorite-button-icon-off {
    order: 2;
    padding: 0;
    margin-#{$ldirection}: 8px;
  }
  .elc-product-quick-view-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    justify-content: center;
    @include breakpoint($medium-up) {
      flex-direction: row;
    }
  }
  .elc-left-column-section {
    padding: 0;
    display: flex;
    flex-direction: column;
    width: auto;
  }
  .elc-right-column-section {
    max-width: 326px;
    display: block;
    .elc-product-badge-wrapper {
      #{$ldirection}: unset;
      margin-bottom: 30px;
      width: 100%;
      text-align: $ldirection;
      @include breakpoint($medium-up) {
        margin-bottom: 0;
      }
    }
    .elc-product-display-name-wrapper {
      text-decoration: none;
      .elc-product-display-name-link {
        line-height: 30px;
        text-align: $ldirection;
      }
    }
    .elc-favorite-button {
      width: 100%;
    }
    .elc-image-slider-images-wrapper {
      display: inline-block;
    }
    .elc-product-full-cta-wrapper {
      background: none;
      box-shadow: none;
      position: unset;
      width: 100%;
      padding: 0;
    }
    .elc-slider-view-wrapper {
      justify-content: flex-start;
    }
    a {
      text-decoration: none;
      &.elc-product-prices-wrapper,
      &.elc-product-prices-clickable-wrapper {
        margin: 0;
      }
    }
  }
}

.elc-search-grid-wrapper {
  .elc-product-item-wrapper {
    &.elc-product-product-name-wrapper {
      .elc-product-name-wrapper {
        width: auto;
        line-height: 20px;
        @include breakpoint($medium-up) {
          width: 177px;
          line-height: 24px;
        }
      }
    }
  }
}

.__floater {
  .__floater__container {
    background-color: $color-cream !important;
  }
  .__floater__arrow {
    polygon {
      fill: $color-cream;
    }
  }
}

// ACCORDIONS
.sd-product-grid {
  .elc-breakpoints-wrapper {
    margin-bottom: 80px;
    @include breakpoint($medium-up) {
      margin-bottom: 112px;
    }
    .slick-track {
      padding: 0;
    }
  }
  .elc-accordion {
    border-radius: 0;
    border: 0;
    background: none;
    margin: 0;
    padding: 0;
    .elc-label-slide-content {
      font: 14px/1.7 $font--lars;
      margin: 0;
      padding-top: 12px;
    }
    .elc-arrow-icon-down,
    .elc-arrow-icon-up {
      width: 46px;
      height: 46px;
      background-image: url('#{$base-theme-path}img/icons/src/cheveron_small--down.svg');
      mask-image: none;
      -webkit-mask-image: none;
      background-color: transparent;
      margin: -10px;
      @include breakpoint($landscape-up) {
        width: 30px;
        height: 30px;
        margin: 0;
      }
    }
    a.elc-product-cta {
      @include button;
      background-color: $black;
      height: 48px;
      margin-bottom: 48px;
      padding-bottom: 8px;
      width: 128px;
    }
    .elc-header-label {
      font: 24px/1.2 $font--malone;
      letter-spacing: unset;
      color: $black;
      text-transform: capitalize;
      max-width: 90%;
      white-space: normal;
    }
    .elc-product-quick-view-shortcut {
      margin: 0;
    }
    .elc-product-detail-wrapper {
      min-height: auto;
    }
    .elc-product-badge {
      width: 150px;
      letter-spacing: 2px;
      @include breakpoint($medium-up) {
        position: absolute;
        width: 192px;
      }
    }
    .elc-product-images-wrapper {
      margin-top: 32px;
      @include breakpoint($medium-up) {
        padding-bottom: 35px;
        margin-top: 0;
      }
    }
    .elc-grid-item-tout {
      margin-bottom: 0;
      padding: 0;
      @include breakpoint($medium-up) {
        padding: 8px 8px 0;
      }
    }
    .elc-grid-item-product {
      margin-bottom: 0;
      padding-bottom: 0;
    }
    .elc-grid-container,
    .elc-product-brief-view {
      margin-bottom: 32px;
      @include breakpoint($medium-up) {
        margin-bottom: 48px;
      }
    }
    .elc-breakpoints-wrapper {
      margin-bottom: 0;
    }
  }
  .elc-accordion-header {
    justify-content: space-between;
    align-items: flex-start;
    outline: 0;
    padding: $accordion-padding-desktop 0;
    &[aria-expanded='true'] {
      padding-bottom: 20px;
    }
  }
  .elc-label-slide {
    overflow: hidden;
  }
}

.sd-product-grid {
  .elc-accordion-header {
    border-top: 1px solid $color-cream-stroke;
  }
  .elc-basic-tout {
    .elc-product-images-wrapper {
      padding-bottom: 0;
    }
  }
}

.sd-product-grid {
  max-width: 1920px;
  margin: 0 auto;
  overflow: hidden;
  .elc-slider-arrow-left {
    margin-#{$ldirection}: 2px;
  }
  .elc-slider-arrow-right {
    margin-#{$rdirection}: 2px;
  }
  .slick-slider {
    .slick-list {
      overflow-y: visible;
      padding-bottom: 1px;
      .slick-track {
        margin-#{$rdirection}: auto;
        margin-top: 3px;
      }
    }
    .slick-track {
      margin-#{$ldirection}: auto;
    }
    .elc-slider-arrow-wrapper {
      z-index: 150;
    }
  }
  &:first-child {
    .elc-accordion-header {
      border-top: none;
    }
  }
  .elc-accordion {
    @include breakpoint($landscape-up) {
      margin: 0 auto;
      max-width: 1254px;
      padding: 0;
    }
    margin: 0 20px;
    min-width: 331px;
    .elc-product-size-wrapper,
    .elc-product-price-wrapper {
      padding: 24px 0;
    }
    .slick-slide {
      .elc-product-brief-wrapper {
        padding-bottom: 0;
      }
    }
    .elc-product-brief-detail-wrapper {
      height: auto;
      padding-bottom: 0;
      .elc-product-item-wrapper .elc-product-cta-wrapper {
        display: none;
      }
    }
    .elc-product-item-wrapper .elc-product-detail-wrapper {
      min-height: auto;
    }
    .elc-product-quick-view-button {
      position: relative;
      &-wrapper {
        min-height: 90px;
      }
    }
    .elc-product-badge-wrapper {
      display: flex;
      justify-content: center;
    }
  }
  .slick-slide {
    .elc-product-brief-wrapper {
      border: none;
      height: auto;
    }
  }
  .elc-product-brief-detail-wrapper {
    .elc-product-price-wrapper {
      text-align: center;
      width: 100%;
    }
  }
}

.sd-product-cross-sell {
  overflow: hidden;
  .slick-slider {
    .slick-list {
      overflow-x: clip;
      overflow-y: visible;
    }
    .elc-slider-arrow-wrapper {
      z-index: 150;
    }
  }
}

// Skeleton Product Full
.sd-full-skeleton {
  .skeleton {
    &-full {
      margin: 0;
      max-width: 1254px;
      padding: 42px 0 0;
    }
    &-breadcrumbs {
      height: 15px;
      margin-bottom: 9px;
      max-width: 150px;
      @include breakpoint($medium-up) {
        #{$ldirection}: 0;
        margin-bottom: 0;
        position: absolute;
        top: 15px;
      }
      @include breakpoint($landscape-up) {
        top: 40px;
      }
    }

    &-column {
      &--image {
        padding: 0 0 8px;
        width: 100%;
        @include breakpoint($medium-up) {
          padding: 0 0 40px;
          margin: 24px 0 0;
          width: 54%;
        }
        @include breakpoint($landscape-up) {
          margin: 0;
        }
        @include breakpoint($xlarge-up) {
          width: 68%;
        }
      }
      &--details {
        margin-top: 10px;
        padding: 0;
        @include breakpoint($medium-up) {
          max-width: 390px;
          width: 46%;
        }
        @include breakpoint($xlarge-up) {
          width: 32%;
        }
      }
    }
    &-image-wrapper {
      align-items: center;
      display: flex;
      flex-direction: column;
      max-width: 472px;
    }
    &--tag {
      height: 10px;
    }
    &--price,
    &--reviews {
      height: 15px;
    }
    &--description {
      height: 100px;
    }
    &--sizes {
      height: 80px;
      margin: 39px 0 15px;
    }
    &--cta {
      height: 50px;
    }
    &--reviews,
    &--wishlist {
      align-self: flex-end;
    }
    &--wishlist {
      order: 7;
      margin-top: 0;
    }
    &--reviews {
      display: none;
      margin-top: 50px;
      @include breakpoint($medium-up) {
        display: block;
      }
      @include breakpoint($landscape-up) {
        margin-top: 40px;
      }
    }
  }

  // Skeleton Product Data

  .elc {
    &-product {
      &-display-name-link {
        @include title--h1;
        margin-top: 2px;
        @include breakpoint($medium-up) {
          margin-top: 10px;
        }
      }
      &-overview-description {
        @include spp-description;
        line-height: 2;
        margin-top: 37px;
        @include breakpoint($medium-up) {
          margin-top: 35px;
        }
      }
    }
    &-breadcrumbs {
      @include spp-description;
      max-width: inherit;
      pointer-events: none;
      transform: translateY(2px);
      z-index: 1;
      @include breakpoint($medium-up) {
        transform: translateY(-3px);
      }
      @include breakpoint($landscape-up) {
        transform: translateY(2px);
      }
      a {
        text-decoration: none;
        display: none;
        &:last-child {
          display: inline-block;
          &:before {
            content: '<';
            display: inline-block;
            padding-#{$rdirection}: 3px;
            @include breakpoint($medium-up) {
              display: none;
            }
            [dir='rtl'] & {
              content: '>';
            }
          }
        }
        @include breakpoint($medium-up) {
          display: inline-block;
        }
      }
      span {
        display: none;
        margin: 0 10px;
        @include breakpoint($medium-up) {
          display: inline-block;
        }
      }
    }

    &-add-to-bag-button-disabled {
      background-color: $color-primary-300;
      border: none;
      cursor: not-allowed;
      color: $color-grey-disabled;
      letter-spacing: 1.25px;
      overflow-x: hidden;
      padding: 0 16px;
      width: 100%;
    }
    &-social-share {
      display: none;
      @include breakpoint($medium-up) {
        display: block;
      }
    }
  }
}

@if $sub-line-as-title == true {
  @include sub-line-as-title;
}

@if $is-lengthy-mpp-cta == true {
  @include is-lengthy-mpp-cta;
}

@if $sd_disabled_toos_cta == true {
  @include sd_disabled_toos_cta;
}

@if $sd_hide_toos_cta == true {
  @include sd_hide_toos_cta;
}

// Engraving overlay background - SPP / MPP / QS
#cboxOverlay.engraving-preview-popup {
  background: $color-black;
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
}
